<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="linebox">
				<div class="echartbox" ref="echart1"></div>
				<div class="echartbox" ref="echart2"></div>
			</div>
			<div class="linebox">
				<div class="echartbox" ref="echart3"></div>
				<div class="echartbox" ref="echart4"></div>
			</div>
			<div class="linebox" style="height: 36%;">
				<div class="echartbox" style="width: 100%;">
					<div style="display: flex;align-items: center;padding: 20px;padding-bottom: 0;">
						<div style="display: flex;align-items: center;">
							<div style="font-size: 12px;">所属届次：</div>
							<el-select v-model="value1" placeholder="请选择" size="small" @change="onSessionChange">
								<el-option label="第一届" value="第一届"></el-option>
								<el-option label="第二届" value="第二届"></el-option>
								<el-option label="第三届" value="第三届"></el-option>
								<el-option label="全部" value="全部"></el-option>
							</el-select>
						</div>

						<div style="display: flex;align-items: center;margin-left: 40px;">
							<div style="font-size: 12px;">民族类型：</div>
							<el-select v-model="value2" placeholder="请选择" size="small" @change="onSessionChange2">
								<el-option label="汉族" value="汉族"></el-option>
								<el-option label="满族" value="满族"></el-option>
								<el-option label="蒙古族" value="蒙古族"></el-option>
								<el-option label="藏族" value="藏族"></el-option>
								<el-option label="全部" value="全部"></el-option>
							</el-select>
						</div>
						<div style="display: flex;align-items: center;margin-left: 40px;">
							<div style="font-size: 12px;">党派类型：</div>
							<el-select v-model="value3" placeholder="请选择" size="small" @change="onSessionChange3">
								<el-option label="九三学社" value="九三学社"></el-option>
								<el-option label="民主党派" value="民主党派"></el-option>
								<el-option label="无党派" value="无党派"></el-option>
								<el-option label="共青团" value="共青团"></el-option>
								<el-option label="全部" value="全部"></el-option>
							</el-select>
						</div>
					</div>
					<div style="width: 100%;height: 80%;" ref="echart5">

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				echarts1: null,
				echarts2: null,
				echarts3: null,
				echarts4: null,
				value1: "全部",
				value2: "全部",
				value3: "全部",
				echarts5: null,
			}
		},
		mounted() {
			this.initCharts1()
			this.initCharts2()
			this.initCharts3()
			this.initCharts4()
			this.initCharts5()
		},
		methods: {
			initCharts1() {
				this.echarts1 = this.$echarts.init(this.$refs.echart1);
				let option = {
					title: {
						text: "委员男女比例",
						left: "center",
						top: 0,
						textStyle: {
							color: "rgb(64,158,255)",
						},
					},

					tooltip: {
						trigger: "item",
						formatter: "{b} : {c} ({d}%)",
					},

					visualMap: {
						show: false,
						min: 500,
						max: 600,
						inRange: {
							//colorLightness: [0, 1]
						},
					},
					series: [{
						name: "访问来源",
						type: "pie",
						radius: "50%",
						center: ["50%", "50%"],
						color: ['rgb(55,212,92)', "rgb(250,55,126)"],
						data: [{
								value: 285,
								name: "男",
							},
							{
								value: 270,
								name: "女",
							}
						],

						label: {
							normal: {
								formatter: ["{c|{c}人}", "{b|{b}}"].join("\n"),
								rich: {
									c: {
										color: "rgb(64,158,255)",
										fontSize: 12,
										fontWeight: "bold",
										lineHeight: 5,
									},
									b: {
										color: "rgb(98,137,169)",
										fontSize: 12,
										height: 40,
									},
								},
							},
						},
						labelLine: {
							normal: {
								lineStyle: {
									color: "rgb(98,137,169)",
								},
								smooth: 0.2,
								length: 30,
								length2: 20,
							},
						},
						itemStyle: {
							normal: {
								shadowColor: "rgba(0, 0, 0, 0.3)",
								shadowBlur: 10,
							},
						},
					}, ],
				};

				this.echarts1.setOption(option);
			},
			initCharts2() {
				this.echarts2 = this.$echarts.init(this.$refs.echart2);
				let option = {
					title: {
						text: "年龄占比",
						left: "center",
						top: 0,
						textStyle: {
							color: "rgb(64,158,255)",
						},
					},

					tooltip: {
						trigger: "item",
						formatter: "{b} : {c} ({d}%)",
					},

					visualMap: {
						show: false,
						min: 500,
						max: 600,
						inRange: {
							//colorLightness: [0, 1]
						},
					},
					series: [{
						name: "访问来源",
						type: "pie",
						radius: "50%",
						center: ["50%", "50%"],
						color: ["rgb(50,100,255)", "rgb(250,55,126)", "rgb(255,72,79)", "rgb(255,115,33)",
							'rgb(55,212,92)'
						],
						data: [{
								value: 174,
								name: "40-50岁",
							},
							{
								value: 88,
								name: "50-55岁",
							},
							{
								value: 21,
								name: "55岁以上",
							},
							{
								value: 47,
								name: "20-30岁",
							},
							{
								value: 141,
								name: "30-40岁",
							}
						],

						label: {
							normal: {
								formatter: ["{c|{c}人}", "{b|{b}}"].join("\n"),
								rich: {
									c: {
										color: "rgb(64,158,255)",
										fontSize: 12,
										fontWeight: "bold",
										lineHeight: 5,
									},
									b: {
										color: "rgb(98,137,169)",
										fontSize: 12,
										height: 40,
									},
								},
							},
						},
						labelLine: {
							normal: {
								lineStyle: {
									color: "rgb(98,137,169)",
								},
								smooth: 0.2,
								length: 30,
								length2: 20,
							},
						},
						itemStyle: {
							normal: {
								shadowColor: "rgba(0, 0, 0, 0.3)",
								shadowBlur: 10,
							},
						},
					}, ],
				};

				this.echarts2.setOption(option);
			},
			initCharts3() {
				this.echarts3 = this.$echarts.init(this.$refs.echart3);
				let option = {
					title: {
						text: '界别团体结构',
						left: 'center',
						top: '0',
						textStyle: {
							color: "rgb(64,158,255)"
						}
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
						textStyle: {
							color: "rgb(64,158,255)",
							fontSize: 12,
						},
					},
					grid: {
						top: 40,
						left: 80,
						right: 20,
						bottom: 30,
					},
					xAxis: {
						type: "category",
						data: ["民族委员会", "法律委员会", "司法委员会", "财政经济委员会", "教育科学委员会"],
						color: "#64e7ff",

						axisLabel: {
							show: true,
							textStyle: {
								color: "#333",
								fontSize: 12,
							},
						},
					},
					yAxis: {
						name: "人数",
						nameTextStyle: {
							color: "#333",
							fontSize: 12,
						},
						type: "value",
						axisLabel: {
							show: true,
							textStyle: {
								color: "#333",
								fontSize: 12,
							}
						},
					},
					series: [{
						itemStyle: {
							color: "#64e7ff",
						},
						label: {
							normal: {
								show: true, //是否显示
								position: "top", //文字位置
								formatter: "{c}", //c后面加单位
								color: "#ffffff",
								fontSize: 24,
							},
						},
						barWidth: 20,
						data: [44, 21, 12, 15, 24, 15],
						type: "bar",
					}, ],
				};

				this.echarts3.setOption(option);
			},
			initCharts4() {
				this.echarts4 = this.$echarts.init(this.$refs.echart4);
				let datas = [{
						name: "九三学社",
						value: 161,
					},
					{
						name: "民主党派",
						value: 102,
					},
					{
						name: "无党派",
						value: 87,
					},
					{
						name: "共青团",
						value: 114,
					},
				];

				var option = {
					color: ["rgba(250, 109, 62, 1)", "rgba(107, 112, 124, 1)", "rgb(55,212,92)",
						"rgba(251, 210, 65, 1)"
					],
					title: {
						text: '界别团体结构',
						left: 'center',
						top: 'center',
						textStyle: {
							color: "rgb(64,158,255)",
							fontWeight: 'bold',
							fontSize: "14",
						}
					},
					legend: {
						itemHeight: 14,
						itemWidth: 14,
						itemGap: 30,
						icon: "rect",
						orient: "horizontal",
						/*top: "center",*/
						bottom: '2%',
						left: 'center',
						align: 'auto',
						textStyle: {
							align: "left",
							color: "#",
							verticalAlign: "middle",
							rich: {
								name: {
									width: 80,
									fontSize: 16,
								},
								value: {
									width: 20,
									align: "right",
									fontFamily: "Medium",
									fontSize: 16,
								},
								rate: {
									width: 10,
									align: "right",
									fontSize: 16,
								},
							},
						},
						data: datas,
						inactiveColor: "rgba(73, 93, 118, 0.5)"

					},
					tooltip: {
						trigger: "item",
						formatter: "{d}%",
						textStyle: {
							color: "rgba(255, 255, 255, 1)"
						},
						backgroundColor: "rgba(0,0,0,0.8)",
						borderColor: "rgba(219, 230, 255, 0.8)",
					},
					series: [{
						name: "",
						type: "pie",
						radius: ["40%", "80%"],
						center: ["50%", "50%"],
						roseType: "radius",
						label: {
							formatter: "{d}%",
						},
						labelLine: {
							length: 1,
							length2: 20,
						},
						data: datas,
					}, ],
				}
				this.echarts4.setOption(option);
			},
			initCharts5() {
				this.echarts5 = this.$echarts.init(this.$refs.echart5);
				this.updateCharts5();
			},
			updateCharts5() {
				// 随机生成数据的方法
				const generateRandomSeriesData = (length) => Array.from({
					length
				}, () => Math.floor(Math.random() * 100));

				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						top: 40,
						left: 50,
						right: 20,
						bottom: 50,
					},
					legend: {
						data: ['所属委员会', '性别', '年龄'],
						bottom: 0,
						left: 'center'
					},
					xAxis: [{
						type: 'category',
						data: ['委员会A', '委员会B', '委员会C', '委员会D', '委员会E'],
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: [{
						type: 'value',
						name: '人数',
						min: 0,
						axisLabel: {
							formatter: '{value}'
						}
					}],
					series: [{
							name: '所属委员会',
							type: 'bar',
							barGap: 0,
							label: {
								show: true,
								position: 'insideTop'
							},
							data: generateRandomSeriesData(5),
							barWidth: 50,
							itemStyle: {
								color: '#64e7ff'
							}
						},
						{
							name: '性别',
							type: 'bar',
							label: {
								show: true,
								position: 'insideTop'
							},
							data: generateRandomSeriesData(5),
							barWidth: 50,
							itemStyle: {
								color: '#91cc75'
							}
						},
						{
							name: '年龄',
							type: 'line',
							smooth: true,
							label: {
								show: true,
								position: 'top'
							},
							data: generateRandomSeriesData(5),
							itemStyle: {
								color: '#fac858'
							}
						}
					]
				};

				this.echarts5.setOption(option, true);
			},
			// 随机数据生成函数
			generateRandomSeriesData(length) {
				return Array.from({
					length
				}, () => Math.floor(Math.random() * 100));
			},

			// 监听选择框变化并更新图表数据
			onSessionChange(value) {
				this.value1 = value;
				this.updateCharts5();
			},
			onSessionChange2(value) {
				this.value2 = value;
				this.updateCharts5();
			},
			onSessionChange3(value) {
				this.value3 = value;
				this.updateCharts5();
			},
		},
	}
</script>

<style lang="scss" scoped>
	.linebox {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 30%;

		.echartbox {
			width: 48%;
			height: 100%;
		}
	}

	.box_main {
		background-color: #fff;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
		overflow: hidden;
		height: 100%;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
</style>