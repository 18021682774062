import { render, staticRenderFns } from "./big_data_weiyuan.vue?vue&type=template&id=ab146dc6&scoped=true&"
import script from "./big_data_weiyuan.vue?vue&type=script&lang=js&"
export * from "./big_data_weiyuan.vue?vue&type=script&lang=js&"
import style0 from "./big_data_weiyuan.vue?vue&type=style&index=0&id=ab146dc6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab146dc6",
  null
  
)

export default component.exports